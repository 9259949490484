const t = {
  //Services page
  annexNumber: {
    lt: 'Aprašo numeris',
    en: 'EN',
    ru: 'RU'
  },
  employeeHeader: {
    lt: 'Naudotojas',
    en: 'EN',
    ru: 'RU'
  },
  additionalInformation: {
    lt: 'Papildoma informacija',
    en: 'EN',
    ru: 'RU'
  },
  amount: {
    lt: 'Kiekis',
    en: 'EN',
    ru: 'RU'
  },
  discount: {
    lt: 'Nuolaida',
    en: 'EN',
    ru: 'RU'
  },
  vatRate: {
    lt: 'PVM tarifas',
    en: 'EN',
    ru: 'RU'
  },
  monthlyPrice: {
    lt: 'Mėn. įkainis',
    en: 'EN',
    ru: 'RU'
  },
  orderCurrency: {
    lt: 'Užsakymo valiuta',
    en: 'EN',
    ru: 'RU'
  },
  startOfPeriod: {
    lt: 'Laikotarpio pradžia',
    en: 'EN',
    ru: 'RU'
  },
  endOfPeriod: {
    lt: 'Laikotarpio pabaiga',
    en: 'EN',
    ru: 'RU'
  },
  partnerDivision: {
    lt: 'Padalinys',
    en: 'EN',
    ru: 'RU'
  },
  employeeInCharge: {
    lt: 'Kliento įgaliotas atstovas',
    en: 'EN',
    ru: 'RU'
  },
  equipment: {
    lt: 'Įranga',
    en: 'EN',
    ru: 'RU'
  },
  menuRent: {
    lt: 'Įrangos nuoma',
    en: 'EN',
    ru: 'RU'
  },
  menuRentArchive: {
    lt: 'Įrangos nuomos archyvas',
    en: 'EN',
    ru: 'RU'
  },
  menuServers: {
    lt: 'Serveriai',
    en: 'EN',
    ru: 'RU'
  },
  menuHosting: {
    lt: 'Hostingas',
    en: 'EN',
    ru: 'RU'
  },
  menuDomain: {
    lt: 'Domenai',
    en: 'EN',
    ru: 'RU'
  },
  menuTelecomunications: {
    lt: 'Telekomunikacijos',
    en: 'EN',
    ru: 'RU'
  },
  //Invoices page
  date: {
    lt: 'Data',
    en: 'EN',
    ru: 'RU'
  },
  paidUntil: {
    lt: 'Apmokėti iki (data)',
    en: 'EN',
    ru: 'RU'
  },
  seriesNumber: {
    lt: 'Serija-numeris',
    en: 'EN',
    ru: 'RU'
  },
  totalWithoutVat: {
    lt: 'Suma (be PVM)',
    en: 'EN',
    ru: 'RU'
  },
  vatTariff: {
    lt: 'PVM tarifas',
    en: 'EN',
    ru: 'RU'
  },
  vatAmount: {
    lt: 'PVM suma',
    en: 'EN',
    ru: 'RU'
  },
  total: {
    lt: 'Iš viso',
    en: 'EN',
    ru: 'RU'
  },
  attachments: {
    lt: 'Prisegti dokumentai',
    en: 'EN',
    ru: 'RU'
  },
  paidTitle: {
    lt: 'Apmokėtos sąskaitos',
    en: 'EN',
    ru: 'RU'
  },
  unpaidTitle: {
    lt: 'Neapmokėtos sąskaitos',
    en: 'EN',
    ru: 'RU'
  },
  // Login page
  loginButtonTitle: {
    lt: 'Prisijungti',
    en: 'EN',
    ru: 'RU'
  },
  InputUserTitle: {
    lt: 'Username',
    en: 'EN',
    ru: 'RU'
  },
  InputPassTitle: {
    lt: 'Password',
    en: 'EN',
    ru: 'RU'
  },
  servicesTitle: {
    lt: 'Paslaugos',
    en: 'EN',
    ru: 'RU'
  },
  invoicesTitle: {
    lt: 'Sąskaitos',
    en: 'EN',
    ru: 'RU'
  },
  helpdeskTitle: {
    lt: 'Pagalba',
    en: 'EN',
    ru: 'RU'
  },
  welcomeTitle: {
    lt: 'Sveiki,',
    en: 'EN',
    ru: 'RU'
  },
  //Helpdesk
  helpdeskFormTitle: {
    lt: 'Forma',
    en: 'EN',
    ru: 'RU'
  },
  helpdeskRequests: {
    lt: 'Išsiųstos užklausos',
    en: 'EN',
    ru: 'RU'
  },
  formInformationFirstLine: {
    lt: 'Prašome kuo tiksliau apibūdinti iškilusią problemą.',
    en: 'EN',
    ru: 'RU'
  },
  formInformationSecondLine: {
    lt: 'Tikslesnis problemos aprašymas - greitesnis atsakymas.',
    en: 'EN',
    ru: 'RU'
  },
  subjectPlaceholder: {
    lt: 'Antraštė',
    en: 'EN',
    ru: 'RU'
  },
  messagePlaceholder: {
    lt: 'Problemos aprašymas',
    en: 'EN',
    ru: 'RU'
  },
  sendButton: {
    lt: 'Siųsti',
    en: 'EN',
    ru: 'RU'
  },
  searchInputName: {
    lt: 'Paieška',
    en: 'Search',
    ru: 'Search'
  },
  searchInputProblem: {
    lt: 'Problema',
    en: 'EN',
    ru: 'RU'
  },
  statusDone: {
    lt: 'Baigta',
    en: 'EN',
    ru: 'RU'
  },
  statusProcessing: {
    lt: 'Vykdomas',
    en: 'EN',
    ru: 'RU'
  },
  statusStart: {
    lt: 'Išsiųsta',
    en: 'EN',
    ru: 'RU'
  },
  filters: {
    lt: 'Filtrai',
    en: 'EN',
    ru: 'RU'
  },
  start: {
    lt: 'Pradžia',
    en: 'EN',
    ru: 'RU'
  },
  end: {
    lt: 'Pabaiga',
    en: 'EN',
    ru: 'RU'
  },
  code: {
    lt: 'Kodas',
    en: 'EN',
    ru: 'RU'
  },
  representative: {
    lt: 'Atstovas',
    en: 'EN',
    ru: 'RU'
  },
  price: {
    lt: 'Kaina',
    en: 'EN',
    ru: 'RU'
  },
  sortBy: {
    lt: 'Rūšiuoti pagal',
    en: 'EN',
    ru: 'RU'
  },
  accountNumber: {
    lt: 'Sąskaitos numertis',
    en: 'EN',
    ru: 'RU'
  },
  ascending: {
    lt: 'Didėjančiai',
    en: 'EN',
    ru: 'RU'
  },
  descending: {
    lt: 'Mažėjančiai',
    en: 'EN',
    ru: 'RU'
  },
  filtering: {
    lt: 'Filtruoti',
    en: 'EN',
    ru: 'RU'
  },
  errorMessage: {
    lt: 'Klaida! Bandykite dar kartą.',
    en: 'EN',
    ru: 'RU'
  },
  allBillsPayed: {
    lt: 'Visos sąskaitos apmokėtos',
    en: 'EN',
    ru: 'RU'
  },
  user: {
    lt: 'Naudotojas',
    en: 'EN',
    ru: 'RU'
  },
  archive: {
    lt: 'Archyvas',
    en: 'EN',
    ru: 'RU'
  },
  question: {
    lt: 'Ar problema buvo sutvarkyta tinkamai?',
    en: 'EN',
    ru: 'RU'
  },
  yes: {
    lt: 'Taip',
    en: 'EN',
    ru: 'RU'
  },
  no: {
    lt: 'Ne',
    en: 'EN',
    ru: 'RU'
  }
};

export default t;

