import React, { useState } from 'react';
//import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//import messages from "../../../i18n/messages/messages";
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Button from '../../../components/UI/Buttons/Button/Button';
import Input from '../../../components/UI/Input/Input';
import ErrorMessage from '../../../components/UI/ErrorMessage/ErrorMessage';

const LoginForm = props => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('')

    const usernameChange = event => {
        setName(event.target.value);
    }

    const passwordChange = event => {
        setPassword(event.target.value);
    }

    const authStart = event => {
        event.preventDefault();
        props.onAuth(name, password);
    }

    //const {intl:{formatMessage}} = props;

    let errorMessage = null;

    let form = ( 
        <div>
            <Input 
                className="loginPage-box-input"
                type="text" 
                id="username" 
                name="_username" 
                labelFor="username" 
                placeholder={'Username'}
                value={name}
                changed={usernameChange} 
            />           
            <Input
                className="loginPage-box-input"
                type="password" 
                id="password" 
                name="_password" 
                labelFor="password"                     
                value={password}
                changed={passwordChange}
                placeholder={'Password'}
            />
            <Button type="submit" title={'Prisijungti'} disabled={!name || !password} /> 
        </div>
    );

    if (props.loading) {
        form = <Spinner/>
    }


    if (props.error) {
        errorMessage = <ErrorMessage message={props.error}/>
    }
    
    return (
        <form method="post" onSubmit={authStart}>
            {errorMessage}
            {form}            
        </form>
    );
}

const mapsStateToProps = state => {
    return {
        data: state.auth.data,        
        isAuth: state.auth.isSignIn,
        loading: state.auth.loading,
        error: state.auth.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (name, password) => dispatch(actions.auth(name, password))
    };
}

LoginForm.propTypes = {
    data: PropTypes.object,
    isAuth: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.object
}

export default connect(mapsStateToProps, mapDispatchToProps)(LoginForm);