export const AUTH_START = "AUTH_START";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_LOAD_DATA = "USER_LOAD_DATA";
export const USER_SIGNED_IN = "USER_SIGNED_IN";
export const USER_LOAD_DATA_FROM_SESSION = "USER_LOAD_DATA_FROM_SESSION";

export const GET_SERVICES_START = "GET_SERVICES_START";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const UPDATE_SERVICES_COMMENT= "UPDATE_SERVICES_COMMENT";
export const UPDATE_SERVICES_COMMENT_FAIL= "UPDATE_SERVICES_COMMENT_FAIL";
export const UPDATE_SERVICES_USER= "UPDATE_SERVICES_USER";
export const UPDATE_SERVICES_USER_FAIL= "UPDATE_SERVICES_USER_FAIL";

export const GET_INVOICES_START = "GET_INVOICES_START";
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_BACKDROP = "CHANGE_BACKDROP";

export const GET_REQUESTS_START = "GET_REQUESTS_START";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_REQUESTS_FAIL = "GET_REQUESTS_FAIL";
