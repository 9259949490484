import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './IconButton.module.scss';

const IconButton = props => {
    let button = null;

    if(props.notNavLink) {
        button = (
            <div className={styles.iconButton} onClick={props.onClick}> 
                    {props.noTriangle ? null : <div className={styles.triangle}></div> }
                    {props.icon ? <i className={props.icon}></i> : null }
                    {props.src ? <div className={styles.imgBox}><img src={props.src} alt={props.alt}></img></div> : null }
                    <span>{props.title}</span>
            </div>
        );
    } else {
        button = (
            <NavLink
            to={props.link}
            activeClassName={styles.active}
            >   
            <div className={styles.iconButton} id="icon" onClick={props.onClick}> 
                    {props.noTriangle ? null : <div className={styles.triangle}></div> }
                    {props.icon ? <i className={props.icon}></i> : null }
                    {props.src ? <div className={styles.imgBox}><img src={props.src} alt={props.alt}></img></div> : null }
                    <span>{props.title}</span>
            </div>
            </NavLink>
        );
    }

    return (
        button
    );
};

export default IconButton;

