import * as actionTypes from './actionTypes';
import authAPI from '../../api/Auth';

export const loadUserData = (loadUserData) => {
    return {
        type: actionTypes.USER_LOAD_DATA,
        data: loadUserData 
    }
}

export const logOut = () => {
    return dispatch => {
        //dispatch({type: actionTypes.AUTH_START});
        authAPI.logout().then(function(data){
            dispatch({
                type: actionTypes.AUTH_LOGOUT,              
                data: data.data
            });
        }).catch(function(error) {
            console.log('Logout error', error);
        })
    }
};


export const auth = (name, password) => {
    return dispatch => {
        dispatch({type: actionTypes.AUTH_START});
        authAPI.auth(name,password).then(function(data){
            dispatch({
                type: actionTypes.USER_LOAD_DATA,                
                data: data.data
            });
            dispatch({type: actionTypes.USER_SIGNED_IN})
        }).catch(function(error) {
            dispatch({
                type: actionTypes.AUTH_FAIL,
                error: error
            })
        })


    }
};

export const setUserDataFromSession = (data) => {
    return {
        type: actionTypes.USER_LOAD_DATA_FROM_SESSION,
        data: data
    }
}


export const getUserData = () => {
    console.log('dddd');
    return dispatch => {
        authAPI.getUserData().then(
            (userData) => {
                dispatch({
                    type: actionTypes.USER_LOAD_DATA,
                    data: userData
                });
            },
            (error) => {
                console.log(error)
            }
        );
    }
}



