import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import Search from '../../components/Search/Search';
import InfoBlockInvoices from '../../components/UI/InfoBlock/InfoBlockInvoices/InfoBlockInvoices';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import Error from '../../components/UI/ErrorMessage/ErrorMessage';

import './InvoicesPage.scss';
import styles from "../../components/Search/Search.module.scss";
import Input from "../../components/UI/Input/Input";
import t from "../../i18n/translations";

const UnPaidInvoices = props => {

    const [name, setName] = useState('');
    const { onGetData, error, loading, unPaidInvoices } = props;

    useEffect(() => {
        onGetData();
    }, [onGetData]);

    const nameChangeHandler = e => {
        setName(e.target.value);
    };

    if(error) {
        return (
            <Error />
        );
    }

    if(loading) {
        return (
            <Spinner />
        );
    }

    let filteredData = '';
    if(unPaidInvoices !== null && loading === false && error === null) {
        filteredData =  unPaidInvoices
          .filter(o => o.seriesNumber.toLowerCase().includes(name.toLowerCase()))
          .map(i => <InfoBlockInvoices key={i.id} lng={props.lng} data={i} />);

    }


    return (
        <React.Fragment>
            {/*<Search/>       */}
            <div className={styles.search}>
                <Input placeholder={t.searchInputName[props.lng]} id="searchName" name="_searchName" value={name} changed={nameChangeHandler} />
            </div>
            {/*{unPaidInvoicesArr}*/}
            {filteredData}
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        unPaidInvoices: state.invoices.unPaidInvoices,
        loading: state.invoices.loading,
        error: state.invoices.error,
        lng: state.language.language
    };     
};

const mapDispatchToProps = dispatch => {
    return {
        onGetData: () => dispatch(actions.getInvoices())
    }; 
};

UnPaidInvoices.propTypes = {
    unPaidInvoices: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(UnPaidInvoices);