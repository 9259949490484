import React from 'react';

const Input = props => {
    const { type, id, name, value, labelFor, className, placeholder, changed, onKeyUp } = props;
    return (
        <div className={className}>
            <label htmlFor={labelFor}></label>
            <input type={type} id={id} name={name} value={value} onChange={changed} placeholder={placeholder} onKeyUp={onKeyUp} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = placeholder}/>
        </div>
    );
};

export default Input;