import HelpDesk from './modal/HelpDesk';

const HelpDeskAPI = {
    async requests () {
        return [
            new HelpDesk(545,"Aurimas Zaleckas", "Neveikia printeris", "Sveiki, šiandien pabandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Mon Apr 01 2019 10:13:00 GMT+0300", "done" ),
            new HelpDesk(456,"Aloyzas Sakalas", "Telefonas", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Mon Apr 01 2019 11:13:00 GMT+0300", "working" ),
            new HelpDesk(123,"Paulius Ališauskas", "Negaliu prisijungti prie paskyros", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Mon Apr 01 2019 12:13:00 GMT+0300", "start" ),
            new HelpDesk(545,"Irina Jankovskaja", "Problemos su sąskaitomis", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Sat Mar 30 2019 09:13:00 GMT+0300", "done" ),
            new HelpDesk(456,"Šlapianka Dešrytė", "Pakvietimas į pasimatymą", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Sun Mar 31 2019 11:13:00 GMT+0300", "done" ),
            new HelpDesk(455,"Vilgaudas Didysis", "Kompiuterio gedimas", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Mon Apr 01 2019 15:13:00 GMT+0300", "working" )     
        ]
    },

    async search(name, problem, status) {
        if(name === "Aurimas" ) {
            return [
                new HelpDesk(545,"Aurimas Aurimas", "Neveikia printeris", "Sveiki, šiandien pabandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Mon Apr 01 2019 10:13:00 GMT+0300", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(456,"Aurimas Aurimas", "Telefonas", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "Mon Apr 01 2019 11:13:00 GMT+0300", "working", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Vykdomi atnaujinimo darbai'},{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Printeris išsiųstas tvarkyti' }] ),
            ]
        } else  {
            return [
                new HelpDesk(456,"Aurimas Aurimas", "Telefonas", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "working", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Vykdomi atnaujinimo darbai'},{'status': 'working', 'time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Printeris išsiųstas tvarkyti' }] ),
                new HelpDesk(545,"Aurimas Aurimas", "Neveikia printeris", "Sveiki, šiandien pabandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(456,"Aurimas Aurimas", "Telefonas", "Reikia naujos SIM korteles", "working", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' }] ),
                new HelpDesk(123,"Paulius Ališauskas", "Negaliu prisijungti prie paskyros", "Neveikia mano emailo paskyra",  "start", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' }] ),
                new HelpDesk(545,"Irina Jankovskaja", "Problemos su sąskaitomis", "Problemos su sąskaitomis. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(456,"Šlapianka Dešrytė", "Pakvietimas į pasimatymą", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(545,"Lietus Zaleckas", "Neveikia printeris", "Sveiki, šiandien pabandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(456,"Aloyzas Sakalas", "Telefonas", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(456,"Šlapianka Dešrytė", "Pakvietimas į pasimatymą", "Sveiki, šiandien pbandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),
                new HelpDesk(545,"Aurimas Zaleckas", "Neveikia printeris", "Sveiki, šiandien pabandžiau įjungti printerį. Deja neveikia. Prašau sutvarkyti.", "done", [{'status': 'start','time': "Mon Apr 01 2019 10:13:00 GMT+0300", 'message' : 'Išsiųsta užklausa' },{'status': 'working','time': "Mon Apr 01 2019 12:13:00 GMT+0300", 'message' : 'Problema pradėta tvarkyti . IT Specialistas Simonas Žukauskas' },{'status': 'done','time': "Tue Apr 02 2019 12:13:00 GMT+0300", 'message' : 'Problema sutvarkyta' }] ),           
            ]
        }        

    }
}


export default HelpDeskAPI;