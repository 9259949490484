import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import LinkButton from "../../components/UI/Buttons/LinkButton/LinkButton";
import PaidInvoices from './PaidInvoices';
import UnPaidInvoices from './UnPaidInvoices';
import t from "../../i18n/translations";

import './InvoicesPage.scss';
//import {ServicesPage} from "../ServicesPage/ServicesPage";

const InvoicesPage = props => {
    //const {intl:{formatMessage}} = props;
    return (        
        <div className="invoicesPage" >     
            <div className="invoicesPage-header">
                <div className="invoicesPage-header-heading">
                    <span>{t.invoicesTitle[props.lng]}</span>
                </div>
                <div className="invoicesPage-header-links">
                    <LinkButton link="/invoices/unpaid" title={t.unpaidTitle[props.lng]} />
                    <LinkButton link="/invoices/paid" title={t.paidTitle[props.lng]} />
                </div>
            </div>
            <div className="invoicesPage-table">
                <Switch>
                    <Route path="/invoices/unpaid"  component={UnPaidInvoices} />
                    <Route path="/invoices/paid" component={PaidInvoices} />
                </Switch>    
            </div>
        </div>    
    );
}

const mapStateToProps = state => {
  return {
    lng: state.language.language
  };
};

export default connect(mapStateToProps)(InvoicesPage);