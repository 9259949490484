import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import Request from './HelpdeskRequest/HelpdeskRequest';
import Search from '../../../components/Search/Search';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Error from '../../../components/UI/ErrorMessage/ErrorMessage';

import styles from './RequestTable.module.scss';

const RequestTable = props => {
    let screen = null;

    if(props.loading) {
        screen = <Spinner/> 
    };

    if(props.error) {
        screen = <Error/>
    }; 

    console.log(props.data)

    if(props.data !== null && props.loading === false) {
        screen = props.data.map( (rslt, index) => 
            <Request 
                key={index} 
                id={rslt.id} 
                nameSurname={rslt.nameSurname} 
                requestTitle={rslt.requestTitle} 
                status={rslt.status}
                request={rslt.request}
                history={rslt.history}
            />
        );
    }

    return (
        <div className={styles.requestTable}>
            <Search />
            <div className={styles.requests}>
                {screen}
            </div> 
        </div>        
    );
};

const mapStateToProps = state => {
    return {
        data: state.requests.data,
        loading: state.requests.loading,
        error: state.requests.error
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         loadRequests: () => dispatch(actions.getRequests())        
//     };
// };

RequestTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object
}

export default connect(mapStateToProps)(RequestTable);
