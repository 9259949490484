import React from 'react';
//import { injectIntl } from "react-intl";
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import LinkButton from '../../components/UI/Buttons/LinkButton/LinkButton';
//import messages from '../../i18n/messages/messages';
import EquipmentRent from './EquipmentRent';
//import Telecomunications from './Telecomunications';

import './ServicesPage.scss';
import t from "../../i18n/translations";

export const ServicesPage = props => {
    //const {intl:{formatMessage}} = props;
  //console.log('se',props);

    return (
        <div className="servicesPage">
            <div className="servicesPage-header">
                <div className="servicesPage-header-heading">
                    <span>{t.servicesTitle[props.lng]}</span>
                </div>
                <div className="servicesPage-header-links">
                    <LinkButton link="/services/rent" title={t.menuRent[props.lng]} />
                  <LinkButton link="/services/rent-archive" title={t.menuRentArchive[props.lng]} />
                    {/* <LinkButton link="/services/telecomunications" title={'menuTelecomunications'} />  */}
                </div>
            </div>
            <div className="servicesPage-table">
                <Switch>
                        <Route path="/services/rent"
                               render={(props) => <EquipmentRent {...props} isActive={true} />}
                        />
                        <Route path="/services/rent-archive"
                               render={(props) => <EquipmentRent {...props} isActive={false} />}
                        />
                        {/* <Route path="/services/telecomunications"  component={Telecomunications} /> */}
                </Switch>                        
            </div>
        </div> 
    ); 
};


const mapStateToProps = state => {
  return {
    lng: state.language.language
  };
};

export default connect(mapStateToProps)(ServicesPage);

//export default ServicesPage;