import React from 'react';
import t from "../../../i18n/translations";


import styles from './ErrorMessage.module.scss'

const ErrorMessage = props => {    
    //const {intl:{formatMessage}} = props;

    return (
        <div className={props.className}>
            <div className={styles.errorMessage}>
                <span>{t.errorMessage[props.lng]}</span>
            </div>
        </div>
    );
};

export default ErrorMessage;