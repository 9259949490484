import React, { useEffect, useState } from 'react';
import "react-table/react-table.css";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as servicesActions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import ErrorMessage from '../../components/UI/ErrorMessage/ErrorMessage'
//import Search from '../../components/Search/Search';
import InfoBlock from '../../components/UI/InfoBlock/InfoBlockServices';

import './ServicesPage.scss';
import styles from "../../components/Search/Search.module.scss";
import Input from "../../components/UI/Input/Input";
import t from "../../i18n/translations";
//mport InfoBlockInvoices from "../../components/UI/InfoBlock/InfoBlockInvoices/InfoBlockInvoices";

export const EquipmentRent = props => {

  const [name, setName] = useState('');
  const {onGetData, data, error, loading} = props;
  //const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    onGetData();
  },[onGetData]);


  const nameChangeHandler = e => {
    setName(e.target.value);
  };

 //console.log('active', props);

  let table = null;
   if(data !== null && loading === false && error === null) {
  //   let infoBlocks = [];
  //   for(let i=0; i <= data.length-1; i++) {
  //     let tariff = (data[i].vatTariff) * 100;
  //     let price = data[i].monthlyPrice.toFixed(2);
  //     // if(i === 0) {
  //     //     infoBlocks.push(
  //     //         <InfoBlock
  //     //             style={{ 'borderTop' : '2px solid #0f9288'}}
  //     //             key={i}
  //     //             annexNumber={data[i].annexNumber}
  //     //             employeeInCharge={data[i].employeeInCharge}
  //     //             selfEnteredComment={data[i].selfEnteredComment}
  //     //             division={data[i].division}
  //     //             startOfPeriod={data[i].startOfPeriod}
  //     //             endOfPeriod={data[i].endOfPeriod}
  //     //             equipmentList={data[i].equipment}
  //     //             equipmentFirst={data[i].equipment[0].title}
  //     //             currency={data[i].currency}
  //     //             vatTariff={tariff}
  //     //             user={data[i].user}
  //     //             monthlyPrice={price}
  //     //         />
  //     //     )
  //     // } else {
  //     infoBlocks.push(
  //       <InfoBlock
  //         key={i}
  //         annexNumber={data[i].annexNumber}
  //         employeeInCharge={data[i].employeeInCharge}
  //         selfEnteredComment={data[i].selfEnteredComment}
  //         division={data[i].division}
  //         startOfPeriod={data[i].startOfPeriod}
  //         endOfPeriod={data[i].endOfPeriod}
  //         equipmentList={data[i].equipment}
  //         equipmentFirst={data[i].equipment[0].title}
  //         currency={data[i].currency}
  //         vatTariff={tariff}
  //         user={data[i].user}
  //         monthlyPrice={price}
  //       />
  //     )
  //     // }

   // }

     //console.log(data);
     //const filteredData =  data.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().indexOf(name) !== -1)).map(i => <InfoBlock key={i.id} {...i} />);
     const filteredData =  data
       .filter(a => a.isActive === props.isActive)
       .filter(o => ['annexNumber', 'employeeInCharge', 'selfEnteredComment', 'user']
         .some(k => String(o[k]).toLowerCase().includes(name.toLowerCase()))).map(i => <InfoBlock key={i.id} lng={props.lng} data={i} />);

  table = (
      <React.Fragment>
        {/*<Search />*/}
        <div className={styles.search}>
          <Input placeholder={t.searchInputName[props.lng]} id="searchName" name="_searchName" value={name} changed={nameChangeHandler} />
        </div>
        <div className="infoBlockWrapper">
          {filteredData}

          {/*{infoBlocks}*/}
        </div>
      </React.Fragment>
    );
  } else if ( error !== null ) {
    table = (
      <ErrorMessage lng={props.lng} />
    );
  } else {
    table = (
      <Spinner />
    );
  }
  return (
    table
  );
}

const mapStateToProps = state => {
  return {
    data: state.services.data,
    error: state.services.error,
    loading: state.services.loading,
    lng: state.language.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetData: () => dispatch(servicesActions.getServices()),
    // onChangeBackdrop: (status) => dispatch(servicesActions.changeBackdrop(status))
  };
};

EquipmentRent.propTypes = {
  data: PropTypes.array,
  error: PropTypes.object,
  loading: PropTypes.bool,
  lng: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentRent);