import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import styles from './LanguageBar.module.scss';

const LanguageBar = props => {
    const changeLanguageHandler = language => {
        props.onChangeLanguage(language.target.id)
    }

    return ( 
        <div className={props.className}>
            <div className={styles.languages}>
                <div>
                    <button id="lt" onClick={changeLanguageHandler}>LT</button>
                </div>
                <div className={styles.languagesBox}>
                    <button id="en" onClick={changeLanguageHandler}>EN</button>
                </div>
                <div>
                    <button id="ru" onClick={changeLanguageHandler}>RU</button>
                </div>
            </div> 
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeLanguage: (language) => dispatch(actions.changeLanguage(language))
    }
}

export default connect(null, mapDispatchToProps)(LanguageBar);

