import * as actionTypes from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    data: null,
    isSignedIn: null 
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case actionTypes.AUTH_LOGOUT:
            window.sessionStorage.removeItem('isSigned');
            return {
                ...state,
                loading: false,
                error: null,
                data: null,
                isSignIn: false,
            };
        case actionTypes.USER_LOAD_DATA:
            window.sessionStorage.setItem('isSigned', JSON.stringify(action.data));
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data,
            };
        case actionTypes.USER_LOAD_DATA_FROM_SESSION:
            return {
                ...state,
                data: action.data,
            };
        case actionTypes.USER_SIGNED_IN:

            return {
                ...state,
                isSignedIn: true
            };
        default:
            return state;
    }
}

export default reducer;