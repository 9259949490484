import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    data: null,
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REQUESTS_START: 
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_REQUESTS_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        case actionTypes.GET_REQUESTS_FAIL: 
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default: 
            return state;        
    };
};

//helpdesk requests

export default reducer;