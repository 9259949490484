import * as actionsTypes from './actionTypes';
import InvoicesAPI from '../../api/Invoices';

export const getInvoices = () => {
    return dispatch => {
        dispatch({
            type: actionsTypes.GET_INVOICES_START
        });
        InvoicesAPI.invoices().then(function (invoices) {
            let paidInvoices = [];
            let unPaidInvoices = []
            for(let i = 0; i <= invoices.length-1; i++) {
                if(invoices[i].isPaid === true) {
                    paidInvoices.push(invoices[i])
                } else {
                    unPaidInvoices.push(invoices[i])
                }
            }
            dispatch({
                type: actionsTypes.GET_INVOICES_SUCCESS,
                paidInvoices: paidInvoices,
                unPaidInvoices: unPaidInvoices
            });
        }).catch(error => {
            dispatch({ 
                type: actionsTypes.GET_INVOICES_FAIL,
                error: error
            })
        });
    };
};