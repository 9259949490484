import React from 'react';
import { Switch, Route } from 'react-router-dom';
//import { injectIntl } from "react-intl";

import LinkButton from '../../components/UI/Buttons/LinkButton/LinkButton';
//import messages from '../../i18n/messages/messages';
import RequestTable from './RequestsTable/RequestsTable';
import Form from './Form/Form';

import './HelpDeskPage.scss';

const Helpdesk = props => {
    //const {intl:{formatMessage}} = props;
    return ( 
        <div className="helpDeskPage">
            <div className="helpDeskPage-header">
                <div className="helpDeskPage-header-heading">
                    <span>{'helpdeskTitle'}</span>
                </div>
                <div className="helpDeskPage-header-links">
                    <LinkButton link='/helpdesk/form' title={'helpdeskFormTitle'}/>
                    <LinkButton link='/helpdesk/requests' title={'helpdeskRequests'}/> 
                    <LinkButton link='/helpdesk/archive' title={'archive'}/> 
                </div>
            </div>
            <div className="helpDeskPage-formBox">
                <Switch>
                    <Route path="/helpdesk/form" component={Form} />
                    <Route path="/helpdesk/requests"  component={RequestTable} />
                </Switch>                
            </div>
        </div> 
    );
}

export default Helpdesk;