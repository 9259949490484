//import Service from './modal/Service'
import axiosInstance from './axios';

//let services = [];

const ServicesAPI = {
    async services() {                 
        return await axiosInstance({
                method: "GET",
                url: "/services"
        }).then(function (response) {
          //console.log('aaa', response);
            // for(let i=0; i <= response.data.bitai_rent.length-1; i++) {
            //     services.push(
            //         new Service
            //             (response.data.bitai_rent[i].id,
            //                 response.data.bitai_rent[i].annexNumber,
            //                 response.data.bitai_rent[i].user,
            //                 response.data.bitai_rent[i].selfEnteredComment,
            //                 response.data.bitai_rent[i].vatTariff,
            //                 response.data.bitai_rent[i].monthlyPrice,
            //                 response.data.bitai_rent[i].currency,
            //                 response.data.bitai_rent[i].startOfPeriod,
            //                 response.data.bitai_rent[i].endOfPeriod,
            //                 response.data.bitai_rent[i].division,
            //                 response.data.bitai_rent[i].employeeInCharge,
            //                 response.data.bitai_rent[i].equipment,
            //             ));
            // }
            // return services;
            return response.data.bitai_rent;
        })
    },

    async setComment(comment,id) {

    }
}

export default ServicesAPI;

