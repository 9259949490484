import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MiniLogo from '../Logo/miniLogo';
import IconButton from '../Buttons/IconButton/IconButton';
import ToggleBar from './Togglebar/ToggleBar';
import Spinner from '../Spinner/Spinner';
import * as roles from '../../../containers/LoginPage/Auth/roles';
//import HelpDeskIcon from '../../../assets/img/helpdesk-icon.png';
import ServicesIcon from '../../../assets/img/ServicesBoxIcon.png';
import InvoicesIcon from '../../../assets/img/invoices-icon.png';
import UserIcon from '../../../assets/img/user-icon.png';

import './SideBar.scss';
import t from "../../../i18n/translations";


const SideBar = props => {
  const [toggle, setToggle] = useState(false);

  let openMenu = toggle ? 'open' : '';
  // let adminPanel = null;

  //const {intl:{formatMessage}} = props;

  if (props.data.roles[0] === roles.ROLE_LOGGED_IN_AS_PARTNER) {
    // let adminPanel = <IconButton link="/helpdesk/form" activeClassName='sideBar-active' title={t.helpdeskTitle[props.lng]}
    //                          src={HelpDeskIcon}/>
  }

  let screen = null;
  if (props.data === null) {
    screen = <Spinner/>;
  } else {
    screen = (
      <div className="sideBar">
        <MiniLogo width="75"/>
        <IconButton link="/services/rent" title={t.servicesTitle[props.lng]} src={ServicesIcon} alt="Services icon"/>
        <IconButton link="/invoices/unpaid" title={t.invoicesTitle[props.lng]} src={InvoicesIcon} alt="Invoices icon"/>
        {/*{adminPanel}*/}
        <div className="sideBar-bottom">
          <IconButton notNavLink title={props.data.company} src={UserIcon} alt="User icon" onClick={() => {
            setToggle(!toggle)
          }}/>
          <ToggleBar toggle={openMenu}/>
        </div>
        <div className="sideBar-toggle">
        </div>
      </div>
    );
  }

  return (
    screen
  );
}

const mapsStateToProps = state => {
  return {
    data: state.auth.data,
    lng: state.language.language
  }
}

SideBar.propTypes = {
  data: PropTypes.object
}

export default connect(mapsStateToProps)(SideBar);