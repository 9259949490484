import React, { useState } from 'react';
import { connect } from 'react-redux';
import Input from '../UI/Input/Input';
import * as actions from '../../store/actions/index';
//import Filters from './Filters/Filters';
import t from "../../i18n/translations";
import styles from './Search.module.scss';

const Search = props => {
  const [name, setName] = useState('');
  // const [filterOpen, setFilterOpen] = useState(false)

  //const {intl:{formatMessage}} = props;

  // useEffect(() => {
  //     search();
  // }, [name])

  const nameChangeHandler = e => {
    setName(e.target.value);
  }


  // let searchTimeout = useRef();
  // const search = () => {
  //     props.startLoading();
  //     if(searchTimeout.current) {
  //         clearTimeout(searchTimeout.current);
  //         searchTimeout.current = null;
  //     }
  //     searchTimeout.current = setTimeout(sendSearchRequest,1000)
  // }

  // const filtersHandler = () => {
  //   setFilterOpen(!filterOpen);
  // }
  //
  // let filtersActive = '';
  // let smoothDropDown = ''
  // if(filterOpen) {
  //   filtersActive = 'activeFilters';
  //   smoothDropDown = 'smoothDropDown'
  // }

  // const sendSearchRequest = () => {
  //     props.onSearch(name);
  // }

  return (
    <div className={styles.search}>
      <Input placeholder={t.searchInputName[props.lng]} id="searchName" name="_searchName" value={name} changed={nameChangeHandler} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    lng: state.language.language
  };
};

const mapsDispatchToProps = dispatch => {
  return {
    onSearch: (name, problem, status) => dispatch(actions.searchRequests(name, problem, status)),
    startLoading: () => dispatch(actions.getRequestsStart())
  }
}

export default connect(mapStateToProps,mapsDispatchToProps)(Search);