class HelpDesk {
    constructor (
        id,
        nameSurname,
        requestTitle,
        request,
        status,
        history
    ) {
        this.id = id;
        this.nameSurname = nameSurname;
        this.requestTitle = requestTitle;
        this.request = request;
        this.status = status;
        this.history = history;
    }
}

export default HelpDesk;