import * as actionTypes from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    data: null,
    updateError: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SERVICES_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        case actionTypes.GET_SERVICES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.UPDATE_SERVICES_COMMENT:
            const {id, comment} = action.data;
            const newState = state.data.map(p =>
                p.id === id ? { ...p, selfEnteredComment: comment } : p
              );
            return {
                ...state,
                data: newState
            }
        case actionTypes.UPDATE_SERVICES_COMMENT_FAIL:
            return {
                ...state,
                updateError: true,
                error: action.error
            }
        case actionTypes.UPDATE_SERVICES_USER:
            const unewState = state.data.map(p =>
                p.id === action.data.id ? { ...p, user: action.data.user } : p
                );
            return {
                ...state,
                data: unewState
            }
        case actionTypes.UPDATE_SERVICES_USER_FAIL:
            return {
                ...state,
                updateError: true,
                error: action.error
            }
        default:
            return state;
    }
};

export default reducer;