import React from 'react';

import Logo from '../../components/UI/Logo/Logo';
import LanguageBar from '../../components/LanguageBar/LanguageBar';
import Auth from './Auth/Auth';

import './LoginPage.scss';


const LoginPage = props => {   
    return (
        <div className="loginPage" >
            <LanguageBar className="loginPage-languageBar"/>
            <div className="container loginPage-box">
                <div className="row justify-content-center">
                    <div>
                        <Logo className="loginPage-box-logo"/> 
                        <Auth/> 
                    </div>   
                </div>                      
            </div> 
        </div>  
    );
}

export default LoginPage;