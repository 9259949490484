import React from 'react';

import styles from './TriangleButton.module.scss'

const TriangleButton = props => (   
    <div className={styles.triangleBox} id={props.id} onClick={props.onClick}>
        <div className={styles.triangle}></div>
    </div>
);


export default TriangleButton;