import * as actionTypes from '../actions/actionTypes';

const initialState = {
    error: null,
    loading: false,
    paidInvoices: null,
    unPaidInvoices: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVOICES_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                unPaidInvoices: action.unPaidInvoices,
                paidInvoices: action.paidInvoices
            }
        case actionTypes.GET_INVOICES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }

};

export default reducer;