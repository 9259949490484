import React, { useState } from 'react';
import moment from 'moment';
//import { injectIntl } from "react-intl";

//import messages from '../../../../i18n/messages/messages';
import TriangleButton from '../../../../components/UI/Buttons/TriangleButton/TriangleButton';
import Button from '../../../../components/UI/Buttons/Button/Button';
import * as stat from './status';

import styles from './HelpdeskRequest.module.scss';

const Request = props => {
    const [ requestToggle, setRequestToggle] = useState(false);

    const { id, nameSurname, requestTitle, request, status, history } = props;

    //const {intl:{formatMessage}} = props;
    let activeClass = ''

    let question = null;    

    if(status === stat.STATUS_DONE) {
        question = (
            <React.Fragment>
                <div className={styles.question}>
                    <div className={styles.dot} id="question_dot" ></div><span> {'question'} </span>
                </div>
                <div className={styles.buttons}>
                    <Button width='12%' title={'yes'}/>
                    <Button width='12%'  title={'no'}/>                        
                </div>
            </React.Fragment>
        );
    }

    let timelineShow = null;
    let timelineReport = [];
    if(history.length > 0) {
        for(let i = 0; i <= history.length-1; i++){
            let status = null;
            if(history[i].status === stat.STATUS_DONE) {
                status = "done";
            } else if(history[i].status === stat.STATUS_WORKING) {
                status = "working";
            } else if(history[i].status === stat.STATUS_START) {
                status = "start";
            }
            timelineReport.push(
                <div className={styles.timelineReport} key={i}>
                    <div className={styles.dot} id={status} ></div><span> {history[i].time} &bull; {history[i].message}</span>
                </div>
            );
        }

    }

    const toggleHandler = () => {
        setRequestToggle(!requestToggle);
    }

    if(requestToggle) {
        activeClass = 'requestsToggle';
        timelineShow = (
            <div className={styles.timeline}>
                {timelineReport} 
                {question}
            </div>
        );

    }
   
    let timeAgo = moment(history[0].time).fromNow(); 

    return (
        <div className={[styles.requests, activeClass].join(' ')}>
            <div className={styles.requestsbox}>
                <div className={styles.requestsboxIcon}>
                    <div className={styles.requestsboxIconDiv}>{id}</div>
                </div>
                <div className={styles.requestsboxTitle}>
                    <h6>{requestTitle}</h6>
                    <span>{request}</span>
                </div>
                <div className={styles.requestsboxInfo}>
                    <span className={styles.name}>{nameSurname} </span>
                    <span> Išsiųsta {timeAgo} </span>                        
                </div>
                <div className={styles.requestsboxButton} id={activeClass}> 
                    <TriangleButton onClick={toggleHandler} />                        
                </div>
                <div className={styles.requestsboxStatus} id={status}>
                </div>
            </div>
            {timelineShow}
        </div>
    );
};

export default Request;