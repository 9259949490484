import React from 'react';
import { Link } from 'react-router-dom';

import styles from './RoundButton.module.scss';

const RoundButton = props => {
    let roundButton = (
        <div className={styles.roundButton} onClick={props.onClick} style={props.style}>
            <i className={props.icon}></i>
        </div>
    );
    if(props.link) {
        roundButton = ( 
            <Link to={props.link} >
                <div className={styles.roundButton} onClick={props.onClick} style={props.style}>
                    <i className={props.icon}></i>
                </div>
            </Link>
        );
    }

    return (
        roundButton
    );
};

export default RoundButton;