import * as actionTypes from './actionTypes';

export const changeLanguage = languageCode => {    
    return {
        type: actionTypes.CHANGE_LANGUAGE,
        language: languageCode
    };
};

export const changeBackdrop = (status) => {    
    return {
        type: actionTypes.CHANGE_BACKDROP,
        status: status
    };
};