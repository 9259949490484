import axiosInstance from "./axios";
//import axios from 'axios';

let counter = 1;

const authAPI = {    
    async auth(name, password) {
        return await axiosInstance({
            method: "POST",
            url: "/login",
            data: {
                'username': name,
                'password': password
            } 
        }).then(function(response) {
            return response
        })
    },

    async logout() {
        return await axiosInstance({
            method: "GET",
            url: "/logout"
        }).then(function(response) {
            return response
        })
    },

    async getUserData() { 
        if(counter === 1) {            
            return {
                username: 'Aurimas',
                fullName: 'Aurimas Zaleckas',
                roles: [
                    'ROLE_CAN_MANAGE_REQUESTS'
                ],
                company: 'UAB, Bitai'
            }
            // return {
            //     username: 'Algis',
            //     nameSurname: 'Algimantas Bernatavičius',
            //     roles: [
            //         'ROLE_CAN_MANAGE_ONLY_INVOICES'
            //     ],
            //     company: 'Virgis ir co, UAB'
            // } 
        }
        counter++;
        return false
    }
}

export default authAPI;
