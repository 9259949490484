import React from 'react';

import logo from '../../../assets/img/bitai_logo.png';

const Logo = props => (
    <div className={props.className}>
        <img src={logo} alt="logo"></img>
    </div> 
);

export default Logo;

