import React from 'react';

const TextArea = props => {
    const { placeholder, name, form, rows, cols, className, value, changed } = props
    return (
        <textarea 
            className={className}
            placeholder={placeholder} 
            name={name} 
            form={form} 
            rows={rows} 
            cols={cols}
            value={value} 
            onChange={changed}   
        >
        </textarea>
    );
}

export default TextArea;