import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './linkButton.module.scss'

const LinkButton = props => {
    return (
        <NavLink to={props.link}
            exact={props.exact}
            activeClassName={styles.active}
            className={styles.linkButton}
        >      
            {props.title}        
        </NavLink>
    );
};

export default LinkButton;