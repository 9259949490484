//import Invoice from './modal/Invoice';
import axiosInstance from "./axios";

const InvoicesAPI = {
    async invoices() {
        return await axiosInstance({
            method: "GET",
            url: "/invoices"
        }).then(function (response) {
          //console.log(response);
           //  let invoices = [];
           //  for (let i = 0; i <= response.data.length-1; i++) {
           //      invoices.push(
           //          new Invoice
           //              (response.data[i].id,
           //                  response.data[i].date,
           //                  response.data[i].paidUntil,
           //                  response.data[i].seriesNumber,
           //                  response.data[i].totalWithoutVat,
           //                  response.data[i].vatTariff,
           //                  response.data[i].vatAmount,
           //                  response.data[i].total,
           //                  '',
           //                  response.data[i].paymentSum,
           //                  response.data[i].isPaid
           //              ));
           // }
           //  return invoices;
          return response.data;
        });
    }
}

export default InvoicesAPI;