import React from 'react';
import { BrowserRouter , Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SideBar from './components/UI/SideBar/Sidebar';
//import SideDrawer from './components/Navigation/SideDrawer/SideDrawer';
import LoginPage from './containers/LoginPage/LoginPage';
import ServicesPage from './containers/ServicesPage/ServicesPage';
import InvoicesPage from './containers/InvoicesPage/InvoicesPage';
import HelpDeskPage from './containers/HelpDeskPage/HelpDeskPage';
import WelcomePage from './containers/WelcomePage/WelcomePage';
//import Backdrop from './components/UI/Backdrop/Backdrop';
import * as actions from './store/actions/index';

import './App.scss';

const App = props => {
    // const [ sideDrawerOpen, setSideDrawerOpen] = useState(false);
    const sessionSigned = JSON.parse(window.sessionStorage.getItem('isSigned'));

    // console.log(props);
    // const drawerToggleClickHandler = () => {
    //     setSideDrawerOpen(!sideDrawerOpen);
    // };

    // componentWillMount() {
    //     this.props.onLoadGetUserData();
    // }

    // let backdrop;
    // let rentBackdrop;

    if (!sessionSigned && !props.data) {
        return <LoginPage />;
    }

    if (sessionSigned && !props.data) {
        //console.log('ssss', props.data);
        props.setUserDataFromSession(sessionSigned);
        // return <LoginPage />;
    }

    // if(props.backdrop) {
    //     rentBackdrop = <Backdrop />
    // }
    //
    // if(sideDrawerOpen) {
    //     backdrop = <Backdrop drawerClickHandler={drawerToggleClickHandler}/>
    // }

    let routes = '';
    // let routes = (
    //     <Switch>
    //         <Route path="/" exact component={LoginPage}/>
    //         <Redirect to="/"/>
    //     </Switch>
    // );

    if (props.data) {
        routes = (
            <div className="home">
                {/*/!*<div style={{ backgroundColor: 'red' , width: '100px', height: '100px' }}>*!/*/}
                {/*<SideDrawer drawerClickHandler={drawerToggleClickHandler} show={sideDrawerOpen} />*/}
                {/*/!*</div>*!/*/}
                {/*/!*<div style={{ backgroundColor: 'blue' , width: '80px', height: '80px' }}>*!/*/}
                {/*{backdrop}*/}
                {/*/!*</div>*!/*/}
                <Switch>
                    <SideBar/>
                </Switch>
                <Switch>
                    <Route path="/" exact component={WelcomePage}/>
                    <Route path="/welcome" component={WelcomePage}/>
                    <Route path="/services" component={ServicesPage}/>
                    <Route path="/invoices" component={InvoicesPage}/>
                    <Route path="/helpdesk" component={HelpDeskPage}/>
                    <Redirect to="/"/>
                </Switch>
                {/*<div style={{ backgroundColor: 'red' , width: '100px', height: '100px' }}>*/}
                {/*{rentBackdrop}*/}
                {/*</div>*/}
            </div>
        );
    };
    return (
        <BrowserRouter>       
            {routes}
        </BrowserRouter>  
    );   
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isSignIn,
        data: state.auth.data,
        backdrop: state.language.backdrop
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserDataFromSession: d => dispatch(actions.setUserDataFromSession(d))
    }
}

App.propTypes = {
    isAuth: PropTypes.bool,
    data: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
