import React, { useState } from 'react';
//import { injectIntl } from "react-intl";

//import messages from "../../../i18n/messages/messages";
import Input from '../../../components/UI/Input/Input';
import Textarea from '../../../components/UI/Textarea/Textarea';
import Button from '../../../components/UI/Buttons/Button/Button';

const Form = props => {
    const [ subject, setSubject] = useState('');
    const [ message, setMessage] = useState('');

    //const {intl:{formatMessage}} = props;  

    const subjectChanged = (e) => {
        setSubject(e.target.value)
    }

    const messageChanged = (e) => {
        setMessage(e.target.value)
    }

    return (
        <div className="helpDeskPage-formBox-form">
            <span>{'formInformationFirstLine)}<br></br> {formatMessage(messages.formInformationSecondLine'}</span>
            <form>
                <Input    
                    className="helpDeskPage-formBox-subject"
                    type="text"  
                    id="subject" 
                    name="_subject" 
                    labelFor="subject"                     
                    placeholder={'subjectPlaceholder'}
                    value={subject}
                    changed={subjectChanged}
                />  
                <Textarea
                    className="helpDeskPage-formBox-message"   
                    type="text" 
                    id="message" 
                    name="_message"  
                    placeholder={'messagePlaceholder'}
                    rows={7}
                    value={message} 
                    changed={messageChanged}                
                />
                <Button type="submit" title={'sendButton'} disabled={!subject || !message}/>
            </form>
        </div>
    );
}

export default Form;