import * as actionTypes from './actionTypes';
import HelpDeskAPI from '../../api/HelpDesk';

export const getRequestsStart = () => {
    return {
        type: actionTypes.GET_REQUESTS_START
    }
};

export const searchRequests = (name, problem, status) => {
    return dispatch => {
        dispatch(getRequestsStart());
        HelpDeskAPI.search(name, problem, status).then( function (results) {
            dispatch({
                type: actionTypes.GET_REQUESTS_SUCCESS,
                data: results
            });
        }).catch( error => {
            dispatch({
                type: actionTypes.GET_REQUESTS_FAIL,
                error: error
            });
        })
    };
};