import React, { useState } from 'react';
//import { injectIntl } from "react-intl";
import { round } from '../../../../helpers';
import t from "../../../../i18n/translations";

//import messages from '../../../../i18n/messages/messages';

import styles from './InfoBlock.module.scss';

const InfoBlockInvoices = props => {
    const [ blockToggle, setBlockToggle ] = useState(false);

    const blockToggleHandler = () => {
        setBlockToggle(!blockToggle);
    }

    const p = props.data;
    const l = props.lng;
    //const {intl:{formatMessage}} = props;

    let biggerScreen = null;
    let active = ''

    if(blockToggle) {
        active = 'blockOpen';
        biggerScreen = (
            <div className={styles.infoBlockBig} >
                <div className={styles.information}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {t.seriesNumber[l]}
                                </td>
                                <td>
                                    {p.seriesNumber}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.date[l]}
                                </td>
                                <td>
                                    {p.date}
                                </td>                    
                            </tr>           
                            <tr>
                                <td>
                                    {t.paidUntil[l]}
                                </td>
                                <td>
                                    {p.paidUntil}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.totalWithoutVat[l]}
                                </td>
                                <td>
                                    {p.totalWithoutVat}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.vatTariff[l]}
                                </td>
                                <td>
                                    {round(p.vatTariff,2)}
                                </td>                    
                            </tr>
                               <tr>
                                <td>
                                    {t.vatAmount[l]}
                                </td>
                                <td>
                                    {round(p.vatAmount,2)}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.total[l]}
                                </td>
                                <td>
                                    {p.total}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.attachments[l]}
                                </td>
                                <td>
                                    {p.attachments.map((v,i) => (
                                      <p className="attachments-p" key={i}><a href={v.url} target="_blank" rel="noopener noreferrer">{v.name}</a></p>
                                      )
                                    )}
                                </td>                    
                            </tr>                           
                        </tbody>
                    </table>
                </div>               
            </div>
        );
    }

    return (
        <div className={styles.infoBlock} style={p.style} >
            <div className={styles.infoBlockSmall} id={active} onClick={blockToggleHandler}  >
                <div className={styles.information}>
                    <div className={styles.headingOne}>
                        <div> {p.seriesNumber} </div>
                    </div>
                    <div className={styles.headingTwo}>
                        {p.date}
                    </div>
                    <div className={styles.headingThree}>
                        {p.paidUntil}
                    </div>
                    <div className={styles.headingFour}>
                        {p.total}
                    </div>
                </div>
            </div>
            {biggerScreen}
        </div>
    );
}


export default InfoBlockInvoices;