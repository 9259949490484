import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RoundButton from '../../Buttons/RoundButton/RoundButton';
import * as actions from '../../../../store/actions/index';

import './Togglebar.scss'

const ToggleBar = props => {
    const logoutHandler = () => {
        props.onLogout();
        sessionStorage.clear();
    }

    return (
        <div className={props.toggle} id='toggle'>
            <div id="toggle-info">
                <h5>{props.userData.fullName}</h5>
                <h6>Administratorius</h6>
            </div>
            <div id="toggle-btn">
                {/* <RoundButton icon="fas fa-cog"  style={{background : "#107691"}} link="/welcome" />                 */}
                <RoundButton icon="fas fa-power-off" onClick={logoutHandler}/>
            </div>
        </div>
    );
};

const mapsStateToProps = state => {
    return {
        userData: state.auth.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logOut())
    }
}

ToggleBar.propTypes = {
    userData: PropTypes.object
}

export default connect(mapsStateToProps, mapDispatchToProps)(ToggleBar);