import React, { useState } from 'react';
import { connect } from 'react-redux';
//import { injectIntl } from "react-intl";

//import messages from '../../../i18n/messages/messages';
import * as actions from '../../../store/actions/index';
import styles from './InfoBlock.module.scss';
import t from "../../../i18n/translations";


const InfoBlock = props => {

    const p = props.data;
    const [ blockToggle, setBlockToggle ] = useState(false);
    const [ inputField, setInputField ] = useState(false);
    const [ inputValue, setInputValue ] = useState(p.selfEnteredComment);
    const [ inputFieldUser, setInputFieldUser ] = useState(false);
    const [ inputValueUser, setInputValueUser ] = useState(p.user);
    //const {intl:{formatMessage}} = props;
    //console.log('props', props.equipment[0].title);

    let tariff = (p.vatTariff) * 100;
    let price = p.monthlyPrice.toFixed(2);

    const blockToggleHandler = () => {
        setBlockToggle(!blockToggle);
    };

    const inputFieldHandler = () => {
        setInputField(!inputField)
    };

    const inputFieldHandlerUser = () => {
        setInputFieldUser(!inputFieldUser)
    };

    const inputChangeHanlder = e => {
        setInputValue(e.target.value)
    };

    const inputChangeHanlderUser = e => {
        setInputValueUser(e.target.value)
    };

    const changeCommentHandler = (e) => {
        // console.log(e)
        //console.log('inputValue', p.id)
        props.onChangeComment({'comment': inputValue}, p.id);
        setInputField(!inputField);
    }

    const changeHandlerUser = (e) => {
        // console.log(e)
        //console.log('inputValue', p.id)
        props.onChangeUser({'user': inputValueUser}, p.id);
        setInputFieldUser(!inputFieldUser);
    }

    let biggerScreen = null;
    let active = '';

    const l = props.lng;


    if(blockToggle) {
        active = 'blockOpen';
        biggerScreen = (
            <div className={styles.infoBlockBig} >
                <div className={styles.information}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {t.annexNumber[l]}
                                </td>
                                <td>
                                    {p.annexNumber}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.user[l]}
                                </td>
                                <td>
                                    {inputFieldUser ?
                                      <div>
                                          <input value={inputValueUser} type="text" onChange={inputChangeHanlderUser}/>
                                          <i className="fas fa-check" onClick={changeHandlerUser}/>
                                          <i className="fas fa-times" onClick={inputFieldHandlerUser}/>
                                      </div>
                                      :
                                      <div>  {p.user} <i className="fas fa-edit" onClick={inputFieldHandlerUser}/></div> }
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.additionalInformation[l]}
                                </td>
                                <td>
                                    {inputField ?
                                      <div>
                                          <input value={inputValue} type="text" onChange={inputChangeHanlder}/>
                                          <i className="fas fa-check" onClick={changeCommentHandler}/>
                                          <i className="fas fa-times" onClick={inputFieldHandler}/>
                                      </div>
                                      :
                                      <div>  {p.selfEnteredComment} <i className="fas fa-edit" onClick={inputFieldHandler}/></div> }
                                </td>                    
                            </tr>  
                            <tr>
                                <td>
                                    {t.vatTariff[l]}
                                </td>
                                <td>
                                    {tariff}%
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.monthlyPrice[l]}
                                </td>
                                <td>
                                    {p.monthlyPrice}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.orderCurrency[l]}
                                </td>
                                <td>
                                    {p.currency}
                                </td>                    
                            </tr>
                               <tr>
                                <td>
                                    {t.startOfPeriod[l]}
                                </td>
                                <td>
                                    {p.startOfPeriod}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.endOfPeriod[l]}
                                </td>
                                <td>
                                    {p.endOfPeriod}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.partnerDivision[l]}
                                </td>
                                <td>
                                    {p.division}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.employeeInCharge[l]}
                                </td>
                                <td>
                                    {p.employeeInCharge}
                                </td>                    
                            </tr>
                            <tr>
                                <td>
                                    {t.equipment[l]}
                                </td>
                                <td>
                                    {p.equipment.map((rslt, index) =>
                                        <div key={index}> {rslt.inventoral} {rslt.title}</div>
                                    )}
                                </td>                   
                            </tr>
                        </tbody>
                    </table>
                </div>
                {p.status ? <div className={styles.status}></div> : null }
            </div>
        );
    }

    return (
        <div className={styles.infoBlock} style={p.style} >
            <div className={styles.infoBlockSmall} id={active} onClick={blockToggleHandler}  >
                <div className={styles.information}>
                    <div className={styles.headingOne}>
                        <div> #{p.annexNumber} </div>
                        <div className={styles.equipment}>{p.equipment[0].title}</div>
                    </div>
                    <div className={styles.headingTwo}>
                        {price} {p.currency}
                    </div>
                    <div className={styles.headingThree}>
                        {p.startOfPeriod} - {p.endOfPeriod} ({p.periodInMonths}mėn.)
                    </div>
                    <div className={styles.headingFour}>
                        {p.user}
                    </div>
                </div>
                {p.status ? <div className={styles.status}></div> : null }
            </div>
            {biggerScreen}
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeComment: (comment, id) => dispatch(actions.setComment(comment,id)),
        onChangeUser: (user, id) => dispatch(actions.setUser(user,id))
        
    }
}

export default connect(null, mapDispatchToProps)(InfoBlock);