import React from 'react'; 
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk';

import 'bootstrap/dist/css/bootstrap.min.css';

//import AppWrapper from './AppWrapper';
import App from './App';
import authReducer from './store/reducers/auth';
import servicesReducer from './store/reducers/ourServices';
import invoicesReducer from './store/reducers/invoices';
import langReducer from './store/reducers/lang';
import requestsReducer from './store/reducers/helpdeskRequests';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    services: servicesReducer,
    invoices: invoicesReducer,
    language: langReducer,
    requests: requestsReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        {/* <AppWrapper />   */}
        <App />
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
