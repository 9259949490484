import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import t from '../../i18n/translations';
import LanguageBar from '../../components/LanguageBar/LanguageBar';
import styles from './WelcomePage.module.scss';

const WelcomePage = props => {
    //const {intl:{formatMessage}} = props;    
    return (
        <div className={styles.welcomePage}>
            <LanguageBar className={styles.languageBar}/>
                <span>
                {t.welcomeTitle[props.lng]} {props.userData.fullName}! <br></br>
                    {props.userData.company}
                </span>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userData: state.auth.data,
        lng: state.language.language
    }
}

WelcomePage.propTypes = {
    userData: PropTypes.object,
    lng: PropTypes.string
}

export default connect(mapStateToProps)(WelcomePage);