import * as actionTypes from '../actions/actionTypes';


const initialState = {
    language: 'lt',
    backdrop: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.language
            }
        case actionTypes.CHANGE_BACKDROP:
        return {
            ...state,
            backdrop: action.status
        }
        default:
            return state;
    }
};

export default reducer;