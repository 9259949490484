import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import Search from '../../components/Search/Search';
import InfoBlockInvoices from '../../components/UI/InfoBlock/InfoBlockInvoices/InfoBlockInvoices';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import Error from '../../components/UI/ErrorMessage/ErrorMessage';
import styles from "../../components/Search/Search.module.scss";
import Input from "../../components/UI/Input/Input";
import t from "../../i18n/translations";
//import InfoBlock from "../../components/UI/InfoBlock/InfoBlockServices";

const PaidInvoices = props => {

    const [name, setName] = useState('');
    const { onGetData, error, loading, paidInvoices } = props;

    useEffect(() => {
        onGetData();
    }, [onGetData]);

    const nameChangeHandler = e => {
        setName(e.target.value);
    };

    //let paidInvoicesArr = null;

    if(error) {
        return (
            <Error />
        );
    }

    if(loading) {
        return (
            <Spinner />
        );
    }

    let filteredData = '';
    if(paidInvoices !== null && loading === false && error === null) {
        filteredData =  paidInvoices
          .filter(o => o.seriesNumber.toLowerCase().includes(name.toLowerCase()))
          .map(i => <InfoBlockInvoices key={i.id} lng={props.lng} data={i} />);

    // if(paidInvoices) {
    //     paidInvoicesArr = [];
    //
    //     for(let i=0; i <= paidInvoices.length-1; i++) {
    //         let vatTariff = paidInvoices[i].vatTariff.toFixed(2);
    //         let vatAmount = paidInvoices[i].vatAmount.toFixed(2);
    //         // if(i === 0) {
    //         //     paidInvoices.push(
    //         //         <InfoBlockInvoices
    //         //             style={{ 'borderTop' : '2px solid #0f9288'}}
    //         //             key={i}
    //         //             seriesNumber={props.paidInvoices[i].seriesNumber}
    //         //             date={props.paidInvoices[i].date}
    //         //             paidUntil={props.paidInvoices[i].paidUntil}
    //         //             total={props.paidInvoices[i].total}
    //         //             totalWithoutVat={props.paidInvoices[i].totalWithoutVat}
    //         //             vatTariff={vatTariff}
    //         //             attachments={props.paidInvoices[i].attachments}
    //         //             vatAmount={vatAmount}
    //         //         />
    //         //     )
    //         // } else {
    //             paidInvoicesArr.push(
    //                 <InfoBlockInvoices
    //                     key={i}
    //                     seriesNumber={props.paidInvoices[i].seriesNumber}
    //                     date={props.paidInvoices[i].date}
    //                     paidUntil={props.paidInvoices[i].paidUntil}
    //                     total={props.paidInvoices[i].total}
    //                     totalWithoutVat={props.paidInvoices[i].totalWithoutVat}
    //                     vatTariff={vatTariff}
    //                     attachments={props.paidInvoices[i].attachments}
    //                     vatAmount={vatAmount}
    //                 />
    //             )
    //         // }
    //
    //     }
    }


    return (
        <React.Fragment>
            {/*<Search/>       */}
            <div className={styles.search}>
                <Input placeholder={t.searchInputName[props.lng]} id="searchName" name="_searchName" value={name} changed={nameChangeHandler} />
            </div>
            {/*{paidInvoicesArr}*/}
            {filteredData}
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        paidInvoices: state.invoices.paidInvoices,
        loading: state.invoices.loading,
        error: state.invoices.error,
        lng: state.language.language
    };     
};

const mapDispatchToProps = dispatch => {
    return {
        onGetData: () => dispatch(actions.getInvoices())
    }; 
};

PaidInvoices.propTypes = {
    paidInvoices: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(PaidInvoices);