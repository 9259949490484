import * as actionTypes from './actionTypes';
import servicesAPI from '../../api/Services';
import axiosInstance from '../../api/axios';

export const getServices = () => {
    return dispatch => {
        dispatch({type: actionTypes.GET_SERVICES_START})
        servicesAPI.services().then(function(services) {
            dispatch({
                type: actionTypes.GET_SERVICES_SUCCESS,
                data: services
            })
        }).catch (error => {
            dispatch({
                type: actionTypes.GET_SERVICES_FAIL,
                error: error
            });
        })
    };
};

export const setComment = (data, id) => {
    return dispatch => {
        axiosInstance({
            method: "POST",
            url: `/bitai-rent/${id}`,
            data: data
        }).then(function (response) {
            dispatch({
                type: actionTypes.UPDATE_SERVICES_COMMENT,
                data: {...data, 'id': id}
            })
        }).catch (error => {
            dispatch({
                type: actionTypes.UPDATE_SERVICES_COMMENT_FAIL,
                error: error
            });
        })
 
    }
}

export const setUser = (data, id) => {
    return dispatch => {
        axiosInstance({
            method: "POST",
            url: `/bitai-rent/${id}`,
            data: data
        }).then(function (response) {
            dispatch({
                type: actionTypes.UPDATE_SERVICES_USER,
                data: {...data, 'id': id}
            })
        }).catch (error => {
            dispatch({
                type: actionTypes.UPDATE_SERVICES_USER_FAIL,
                error: error
            });
        })
 
    }
}
